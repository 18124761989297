<template>
  <div class="catalog">
    <DataTable
        id='dt-catalog'
        class='p-datatable-catalog'
        :rowGroupMode="this.groupBy"
        :groupRowsBy="this.groupByVal"
        :value="records"
        :paginator="true"
        :rows="100"
        :rowsPerPageOptions="[10,20,50,100]"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['file_name', 'created_at']"
        @page="onPageChange"
    >
      <template #header>
        <div class="flex justify-content-end">
          <span class="p-input-icon-left ">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Search" />
          </span>
        </div>
        <div class="flex">
          <label class='d-flex lbl-group-by' for='group_by'>Group By</label>
          <select v-model=groupByVal id='group_by' class='group-by' @change='updateGroupByDisplay'>
            <option value="">No Grouping</option>
            <option value="reference_num">Reference Number</option>
            <option value="audience">Audience</option>
            <option value="doc_name">Document Name</option>
            <option value="doc_type">Document Type</option>
            <option value="project_id">Project ID</option>
            <option value="version_id">Version ID</option>
            <option value="vertical">Vertical</option>
            <option value="language">Language</option>
          </select>
        </div>
      </template>
      <Column field="file_name" header="File Name" :sortable="true" :click='setHeaderColors()'></Column>
      <Column field="created_at" header="Date" :sortable="true"></Column>
      <Column field="edit" header="Actions" :exportable="false">
        <template #body="slotProps">
          <span class='edit-col'>
            <a class='edit' @click="duplicate(slotProps.data)">Duplicate</a>
            <a class='edit' @click="edit(slotProps.data)">Edit</a>
            <a class='delete' @click="deleteRecord(slotProps.data)">Delete</a>
          </span>
        </template>
      </Column>
      <template #groupheader="slotProps">
        <span>{{ this.groupByDisplay }} - {{slotProps.data?.[this.groupByVal] }}
          <icon class='pi pi-trash group-delete-icon' v-if='this.groupByDisplay === "Reference Number"' @click="deleteRecordGroup(slotProps.data?.[this.groupByVal])"></icon>
        </span>
      </template>
    </DataTable>

    <Dialog v-model:visible="this.editItem" :style="{width: '650px'}" header="Edit" :modal="true" class="p-fluid">
      <div class="modal-body">
        <div class='input-row-modal'>
          <label class='d-flex' for='document-name'>Document Name</label>
          <input v-model=form.doc_name id='document-name' placeholder='document name' />
        </div>
        <div class='input-row-modal'>
          <label class='d-flex' for='document-type'>Document Type</label>
          <select v-model=form.doc_type id='document-type'>
            <option disabled value="">Please select one</option>
            <option value="Fly">Flyer (Fly)</option>
            <option value="Broch">Brochure (Broch)</option>
            <option value="CS">Casestudy (CS)</option>
            <option value="DATA">Datasheet (DATA)</option>
            <option value="InfoGph">Infographic (InfoGph)</option>
            <option value="Inst">Instructional Guide (Inst)</option>
            <option value="PPT">Presentation (PPT)</option>
            <option value="Promo">Promo (Promo)</option>
            <option value="Rpt">Report/EBook (Rpt)</option>
            <option value="ST">Sales Tool (ST)</option>
            <option value="OList">Ordered List (OList)</option>
            <option value="WP">White Paper (WP)</option>
            <option value="PostCd">Postcard (PostCd)</option>
            <option value="CompGuide">Comparison Guide (CompGuide)</option>
            <option value="Slick">Slick (Slick)</option>
            <option value="News">Newsletter (News)</option>
            <option value="Blog">Blog (Blog)</option>
          </select>
        </div>
        <div class='input-row-modal'>
          <label class='d-flex' for='version-id'>Version ID</label>
          <input v-model=form.version_id id='version-id' placeholder='456' />
        </div>
        <div class='input-row-modal'>
          <label class='d-flex' for='project-id'>Project ID</label>
          <input v-model=form.project_id id='project-id' placeholder='123' />
        </div>
        <div class='input-row-modal'>
          <label class='d-flex' for='audience'>Audience</label>
          <select v-model=form.audience id='audience'>
            <option disabled value="">Please select one</option>
            <option value="DIR">Intermedia (DIR)</option>
            <option value="CBRND">Cobrand (CBRND)</option>
            <option value="PLR">White Label (PLR)</option>
            <option value="ToPLR">To Partners (ToPLR)</option>
            <option value="Advisor">Advisor (Advisor)</option>
            <option value="CORP">Corp (CORP)</option>
            <option value="SP">Service Providers (SP)</option>
            <option value="ELVT">Elevate (ELVT)</option>
            <option value="ASCND">Ascend (ASCND)</option>
            <option value="BYOC">BYOC (BYOC)</option>
          </select>
        </div>
        <div class='input-row-modal'>
          <label class='d-flex' for='vertical'>Vertical</label>
          <select v-model=form.vertical id='vertical'>
            <option disabled value="">Please select one</option>
            <option value="FS">Financial Services (FS)</option>
            <option value="HC">Healthcare (HC)</option>
            <option value="HT">High-tech (HT)</option>
            <option value="L">Legal (L)</option>
            <option value="M">Manufacturing (M)</option>
            <option value="AEC">Architecture, Engineering, Construction (AEC)</option>
          </select>
        </div>
        <div class='input-row-modal'>
          <label class='d-flex' for='language'>Language</label>
          <select v-model=form.language id='language'>
            <option disabled value="">Please select one</option>
            <option value="EN">English (EN)</option>
            <option value="FR">French (FR)</option>
            <option value="NL">Dutch (NL)</option>
            <option value="DE">German (DE)</option>
            <option value="IT">Italian (IT)</option>
            <option value="RU">Russian (RU)</option>
            <option value="ES">Spanish (ES)</option>
            <option value="FRCA">Canadian French (FRCA)</option>
          </select>
        </div>
        <div class='modal-preview-cont'>
          <span id='modal-preview-single' class='modal-preview '>{{ form.reference_num }}-{{ form.version_id }}_{{ form.project_id }}_{{ form.audience }}_{{ form.doc_name }}_{{ form.doc_type }}_{{ form.vertical }}_{{ form.language }}_{{ form.date }}</span>
        </div>
        <div>
          <button class="btn btn-edit" @click='saveEdit'>Edit</button>
        </div>
      </div>
    </Dialog>
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import ConfirmDialog from 'primevue/confirmdialog';
import { FilterMatchMode } from 'primevue/api';
import { request } from "@/config/axios";

export default {
  async mounted() {
    await this.getRecords();
    this.updateGroupByDisplay();
  },
  components: {
    DataTable,
    Column,
    Dialog,
    ConfirmDialog,
    InputText
  },
  data() {
    return {
      form: {
        id: 0,
        file_name: '',
        reference_num: '123',
        date: '042722',
        audience: '',
        doc_name: '',
        doc_type: '',
        project_id: '',
        version_id: '',
        vertical: '',
        language: '',
      },
      editItem: false,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      groupBy: '',
      groupByVal: 'reference_num',
      groupByDisplay: '',
      records: [],
    };
  },
  methods: {
    async getRecords() {
      await request
          .get("records")
          .then((response) => {
            this.records = response.data;
            this.updateGroupByDisplay();
          })
          .catch((error) => {
            console.log(error.response.data.error);
          });
    },
    edit(item) {
      this.form = item;
      const date = item.file_name.split('_');
      this.form.date = date[date.length - 1];
      this.editItem = true;
    },
    async duplicate(item) {
      await this.$store.dispatch("updateDuplicationRecord", item);
      this.$router.push('Form');
    },
    deleteRecord(item) {
      this.$confirm.require({
        message: 'Are you sure you\'d like to delete this record?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.confirmDelete(item)
        },
        reject: () => {}
      });
    },
    deleteRecordGroup(referenceNum) {
      this.$confirm.require({
        message: 'Are you sure you\'d like to delete these groups of records? Reference Number: ' + referenceNum,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.confirmDeleteRecordGroup(referenceNum)
        },
        reject: () => {}
      });
    },
    async confirmDeleteRecordGroup(referenceNum) {
      const records = this.records.filter((item) => {
        return item.reference_num === referenceNum;
      });

      await request.delete('records/group', {
        data: {
          records: records
        }
      })
          .then(() => {
            this.records = this.records.filter((record) => record.reference_num !== referenceNum);
            this.$toast.add({severity:'success', summary: 'Success', detail: 'Records Deleted', life: 3000});
          })
          .catch((error) => console.log(error));
    },
    async confirmDelete(item) {
      await request.put('records/' + item.id + '/delete')
          .then(() => {
            this.records = this.records.filter((record) => record.id !== item.id);
            this.$toast.add({severity:'success', summary: 'Success', detail: 'Record Deleted', life: 3000});
          })
          .catch((error) => console.log(error));
    },
    async saveEdit() {
      this.form.file_name = document.getElementById('modal-preview-single').innerHTML;

      const data = this.form;

      await request.put('/records/' + this.form.id, data)
          .then((response) => {
            console.log(response.data);
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Record saved",
              life: 3000,
            });
          })
          .catch((error) => {
            console.log(error.response.data);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Failed to save record",
              life: 3000,
            });
          });

      this.editItem = false;
      this.resetEdit();
    },
    resetEdit() {
      this.form = {
        file_name: '',
        reference_num: '123',
        date: '042722',
        audience: '',
        doc_name: '',
        doc_type: '',
        project_id: '',
        version_id: '',
        vertical: '',
        language: '',
      };
    },
    updateGroupByDisplay() {
      const select = document.getElementById("group_by");

      this.groupByDisplay = select.options[select.selectedIndex].text;

      if (select.value !== "") {
        this.groupBy = "subheader";
      } else {
        this.groupBy = "";
      }

      this.$nextTick(() => {
        this.setHeaderColors();
      });
    },
    async setHeaderColors() {
      const headers = document.getElementsByClassName("p-rowgroup-header");

      for (let i = 0; i < headers.length; i++) {
        const header = headers.item(i);
        if (i % 2 === 0) {
          header.style.background = "rgba(5,175,242,0.25)";
        } else {
          header.style.background = "rgba(255,213,0,0.86)";
        }
      }
    },
    onPageChange() {
      this.$nextTick(() => {
        this.setHeaderColors();
      });
    },
    watch: {
      groupByVal() {
        this.$nextTick(() => {
          this.setHeaderColors();
        });
      },
    },
    waitForElm(selector) {
      return new Promise(resolve => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
      });
    }
  }
};
</script>
<style >
.catalog {
  display: flex;
  justify-content: center;
  margin: 25px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-row-modal {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
}

.input-row-modal select {
  padding: 10px;
  margin: 5px;
  width: 350px;
}

.input-row-modal input {
  padding: 10px;
  margin: 5px;
  width: 350px;
}

.input-row-modal label {
  margin-left: 10px;
}

.modal-preview-cont {
  margin: 15px;
  padding: 5px;
}

.modal-preview {
  font-size: 16px;
}

.btn-edit {
  width: 240px;
  height: 39px;
  left: 537px;
  top: 610px;
  margin: 20px;
  font-size: 18px;
}

.p-datatable-catalog{
  width: 80%;
  color: #00A4FF;
}

.p-column-header-content {
  color: #888888;
}

.p-datatable-tbody > tr > td  {
  color: #888888;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background: var(--background-color-primary);
  border: 1px solid var(--background-color-primary);
  color: var(--text-primary-color);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--background-color-primary);
  border: 1px solid var(--background-color-primary);
  color: #00A4FF;
}

.p-paginator .p-paginator-next .p-paginator-next {
  color: #00A4FF;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  background: var(--background-color-primary);
  border: 1px solid var(--background-color-primary);
}

.p-paginator .p-dropdown {
  background: var(--background-color-primary);
  border: 1px solid var(--background-color-primary);
}

.p-dropdown-panel p-component p-ripple-disabled {
  background: var(--background-color-primary);
  border: 1px solid var(--background-color-primary);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item .p-highlight {
  background: #00A4FF;
}

.p-dialog .p-dialog-header + .p-dialog-content {
  color: #888888;
}

.p-dialog .p-dialog-header {
  color: #888888;
}

.p-dialog .p-dialog-footer {
  background: var(--background-color-secondary);
  border: 1px solid var(--background-color-primary);
}

.p-button {
  background: #00A4FF;
  border: 1px solid #00A4FF;
}

.p-datatable .p-datatable-header {
  background: var(--background-color-secondary);
  border: none;
  color: #212529;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-tbody > tr > td {
  /*background: var(--background-color-secondary);*/
  border-top: 1px solid var(--accent-color);
}

.p-datatable .p-datatable-thead > tr > th {
  /*background: var(--background-color-secondary);*/
  border-top: 1px solid var(--accent-color);
}

.p-rowgroup-header {
  font-weight: bold;
}

.p-datatable .p-paginator-bottom {
  background: var(--background-color-secondary);
  border-top: 1px solid var(--accent-color);
}

.p-inputtext {
  background: var(--background-color-primary);
  border-top: 1px solid var(--accent-color);
}

.p-dialog .p-dialog-header {
  background: var(--background-color-secondary);
  border-top: 1px solid var(--background-color-secondary);
}
.p-dialog .p-dialog-header + .p-dialog-content {
  background: var(--background-color-secondary);
  border-top: 1px solid var(--accent-color);
}

.p-datatable .p-sortable-column.p-highlight {
  background: var(--background-color-primary);
  border: 1px solid var(--accent-color);
}

.p-datatable .p-sortable-column:hover {
  background: var(--background-color-primary) !important;
  border: 1px solid var(--accent-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #00A4FF;
}

.edit-col {
  display: flex;
  justify-content: space-around;
}

.edit-col a {
  cursor: pointer;
}

.edit-col .edit {
  color: #04A3FF;
}

.edit-col .delete {
  color: #FA373B;
}

.p-rowgroup-footer td {
  font-weight: 700;
}

::v-deep(.p-rowgroup-header) {
span {
  font-weight: 700;
}

.p-row-toggler {
  vertical-align: middle;
  margin-right: .25rem;
}
}

.lbl-group-by {
  font-weight: lighter;
  color: #888888;
  padding-left: 10px;

}

.group-by {
  padding: 10px;
  margin: 10px;
  width: 240px;
}

.group-delete-icon {
  margin: 0px 10px;
  font-size: 18px;
  color: red;
  cursor: pointer;
}
</style>
